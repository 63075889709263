
// include Swiper slideshow library
//=require ../../lib/nolimitsforweb-swiper/js/swiper.js

(function(){
  'use strict';

  var defaults = {
    direction: 'horizontal',
    loop: true,
    spaceBetween: 30, // 30px aka 1.875rem
    effect: 'slide',

    // navigation
    pagination: '.pagination',
    nextButton: '.next',
    prevButton: '.prev',

    // localization
    prevSlideMessage: 'Previous image',
    nextSlideMessage: 'Next image',
    firstSlideMessage: 'This is the first image',
    lastSlideMessage: 'This is the last image',
    paginationBulletMessage: 'Go to image {{index}}',

    // disable multiple slides on mobile view
    breakpoints: {
      619: {
        slidesPerView: 1
      },
    }
  };

  function init () {

    // set global collector variable
    window.twoTheme = { slideshows : {} }

    var globalCollector = window.twoTheme.slideshows,
        slideshows  = document.querySelectorAll('.swiper-container'),
        slideshow   = null,
        randomID    = null,
        optionsJSON = null,
        options     = null;

    for (var i=0; i<slideshows.length; i++) {
      slideshow = slideshows[i];

      // generate and set random ID for slideshow element
      randomID = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      slideshow.setAttribute('id', randomID);

      // get custom options from slideshow element
      optionsJSON = slideshow.getAttribute('data-options');
      if (optionsJSON !== null) {
        // replace ' with " and parse json into object
        options = JSON.parse( optionsJSON.split('\'').join('\"') );
      } else {
        options = {};
      }

      // merge custom and default options
      for (var name in defaults) {
        if (options[name] === undefined) {
          options[name] = defaults[name];
        }
      }

      // remove empty options
      for (var name in options) {
        if (options[name] == '' || options[name] == undefined) {
          delete options[name];
        }
      }

      // global collect Swiper instances
      globalCollector[randomID] = new Swiper('#'+ randomID, options);
    }
  }

  init();

})();
